import $ from 'jquery';
import jQuery from 'jquery';

import whatInput from 'what-input';
window.jQuery = jQuery;
window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import './lib/cookieinfo';




$(document).foundation();


//--heroSlider
 jQuery(document).ready(function(){
      var heroSlider = jQuery('.heroSlider');
      heroSlider.owlCarousel({
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        //nav: true,
        mouseDrag: false,
        touchDrag: true,
        items:1,
        loop:true,
        autoplay:true,
        dots:true,
        navText:['<span class="icon icon-chevron-thin-left" aria-hidden="true"></span>','<span class="icon icon-chevron-thin-right" aria-hidden="true"></span>'], //we will be using font awesome icon here
      });
      //heroSlider.on("changed.owl.carousel", function(event){
          // selecting the current active item
          //var item = event.item.index-2;
          // first removing animation for all captions
         //$('.item-caption').removeClass('animated zoomIn');
         //$('.owl-item').not('.cloned').eq(item).find('.item-caption').addClass('animated zoomIn');
          //$('.bg-caption').removeClass('animated fadeIn');
          //$('.owl-item').not('.cloned').eq(item).find('.bg-caption').addClass('animated fadeIn');
     // })
      // No animation in other captions so we need to add animation to owl-item while slide changed.
    });

//--slider page
    jQuery(document).ready(function(){
       jQuery('.slide').owlCarousel({
       animateIn: 'slideInLeft',
       animateOut: 'slideOutRight',
       items:1,
       dots:true,
       mouseDrag: false,
       touchDrag: true,
       loop:true,
       center:true,
       autoplay:true,
   });
 });
//--about as
    jQuery(document).ready(function(){
      jQuery('.about_js').owlCarousel({
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',
      pagination : true,
      items:1,
      dots:false,
      mouseDrag: false,
      touchDrag: false,
      loop:true,
      center:false,
      autoplay:true,
      navText:['<span class="icon icon-chevron-thin-left" aria-hidden="true"></span>','<span class="icon icon-chevron-thin-right" aria-hidden="true"></span>'], 
   });
});

//--button-up
 jQuery(document).ready(function(){
   var btn = $('.button-up');
      $(window).scroll(function() {
       if ($(window).scrollTop() > 1800) {
        btn.addClass('show');
        } else {
        btn.removeClass('show');
     }
   });
 });
//--AOS--scroll
  $(document).ready(function() {
    AOS.init({once:true});
  });
